import React from 'react'
import { Link } from 'gatsby'
import Logo from './logo'

function SidebarContents() {
  return (
    <div>
      <div className="logo">
        <Link to="/">
          <Logo />
        </Link>
      </div>
    </div>
  )
}

class Navbar extends React.Component {
  constructor(props) {
    super(props)
  }

  render() {
    return (
      <div className="header">
        <div className="container-fluid container-max-width header-bg-img h-100">
          <div className="pattern-bg" />
          <div className="logo-wrapper">
            <Logo />
          </div>
        </div>
      </div>
    )
  }
}

export default Navbar
