import React from 'react'

export default function() {
  return (
    <div className="footer">
      <div className="container-fluid container-max-width footer-bg-img">
        <div>&#9400;{new Date().getFullYear()} Toy Or Game. All Rights Reserved.</div>
      </div>
    </div>
  )
}
