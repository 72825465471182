import React from 'react'
import Head from './head'
import Navbar from './navbar'
import Footer from './footer'
import '../style/theme.scss'

const Layout = ({ children }) => {
  return (
    <React.Fragment>
      <Head />
      <Navbar />
      <div className="container-fluid container-max-width main-content">{children}</div>
      <Footer />
      <div className="modal-dummy-height" />
    </React.Fragment>
  )
}

export default Layout
