import React from 'react'
import Helmet from 'react-helmet'
import { useStaticQuery, graphql } from 'gatsby'

function Header() {
  const query = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          icon
        }
      }
    }
  `)
  return (
    <Helmet>
      <link rel="icon" href={query.site.siteMetadata.icon} type="image/png" />
    </Helmet>
  )
}

export default Header
